import React from "react";
import ScrollMagic from "scrollmagic";
import {stack as Menu} from "react-burger-menu";
import {menuStyles} from "./styles";
import {
    ImageContainer,
    Image,
    ImagePreloader,
    Section,
} from "../components/styledcomponents";
import {content} from "../etc/content";
import {Imprint} from "./imprint";
import {KerstinWoerdehoff} from "./kerstinwoerdehoff";
import {LilianeZuuring} from "./lilianezuuring";
import {About} from "./about";
import {playerContent, AudioPlayer} from "./audioplayer";

export class TIGIPMainView extends React.Component {

    constructor(){
        super();
        this.state = {
            currentImage: 1,
            img: null,
            showImage: false,
            currentScene: "section_dummy_",
            menuOpen: false
        };
        this.breakpoint = this.getBreakpoint();
        this.controller = new ScrollMagic.Controller();
        this.defaultDuration = window.innerHeight;
        this.onResize = this.onResize.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.innerWidth = window.innerWidth;
    }

    getBreakpoint(){
        return window.innerWidth > 1000 ? "maxWidth" : window.innerWidth > 700 ? "mediumWidth" : "smallWidth";
    }

    getDurationForElement(id){
        return document.getElementById(id).offsetHeight;
    }

    onResize(){
        const newBreakpoint = this.getBreakpoint();
        if (newBreakpoint !== this.breakpoint){
            this.breakpoint = newBreakpoint;
            this.scrollTo("#Start");
            window.location.reload();
        }
        if (window.innerWidth !== this.innerWidth){
            this.innerWidth = window.innerWidth;
            this.scrollTo("#Start");
            window.location.reload();
        }
    }

    onToggleMenu(state) {
        this.setState({menuOpen: state.isOpen})
    }

    closeMenu(){
        this.setState({menuOpen: false});
    }

    scrollTo(id){
        this.setState({currentPage: "home"});
        this.controller.scrollTo(id);
        this.closeMenu();
    }

    setPage(id){
        this.setState({currentPage: id});
        this.closeMenu();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    componentDidMount() {
        /* resize handler */
        window.addEventListener('resize', this.onResize);

        /* content */
        const setState = this.setState.bind(this);
        const duration = this.defaultDuration;

        content.forEach((item, idx) => {
            const triggerElement = "#" + item.sectionId;
            const duration = this.getDurationForElement(item.sectionId);
            const triggerHook = 0.8;
            const nextItem = content[idx + 1];
            const newState = {
                currentScene: item.sectionId,
                nextImg: nextItem?.image
        }
            new ScrollMagic.Scene({triggerElement: triggerElement, duration: duration, triggerHook: triggerHook})
                .on("enter leave", (e) => {
                    if (e.type === "enter") {
                        /* change content */
                        setState(newState);
                        const imageState = this.state.currentImage === 1 ?
                                {
                                    img: item.image,
                                    imagePosition: item?.imagePosition,
                            showImage: true,
                            currentImage: 2
                    }
                    :
                        {
                            img2: item.image,
                                imagePosition2: item?.imagePosition,
                            showImage2: true,
                            currentImage: 1
                        };
                        if (item.image && imageState) {
                            setState(imageState);
                        }
                        ;
                    } else {
                        const newState = this.state.currentImage === 1 ? {showImage2: false} : {showImage: false};
                        setState(newState);
                    }
                })
                .addTo(this.controller);
        });

        /* scroll to audio via QR-Code redirect */
        setTimeout(
            () => {
                const queryString = window.location.search;
                if (queryString.startsWith("?") && queryString.length > 1){
                    const param = queryString.substr(1);
                    this.setState({audioPlayer: param});
                }

                if (window.history?.pushState) {
                    window.history.pushState({path:window.origin},'',window.origin);
                }
            }
            ,
            500
        );
    }

    render(){
        const {currentPage, currentScene, img, img2, imagePosition, imagePosition2, showImage, showImage2, nextImg, menuOpen, audioPlayer} = this.state;
        const orientation = (window.innerWidth/window.innerHeight) > 1 ? "landscape" : "portrait";

        return <React.Fragment>
            { !audioPlayer && <Menu right styles={menuStyles} isOpen={menuOpen} onStateChange={this.onToggleMenu}>
                <div onClick={(e) => {this.scrollTo("#Start")}}>Durch Deutschland</div>
                <div onClick={(e) => {this.scrollTo("#KARTE01")}}>Die erste Wanderung</div>
                <div onClick={(e) => {this.scrollTo("#KARTE02")}}>Die zweite Wanderung</div>
                <div></div>
                <div><b></b></div>
                <div></div>
                <div onClick={() => {this.setPage("About")}}><b>About / Presse / Awards</b></div>
                <div onClick={() => {this.setPage("Imprint")}}><b>Impressum / Datenschutz</b></div>
            </Menu> }

            { currentPage === "Imprint" && <Imprint /> }
            { currentPage === "About" && <About /> }

            { audioPlayer && <AudioPlayer mobile audioId={audioPlayer} /> }

            {!audioPlayer && <div>
                <ImageContainer>
                    <Image src={img} showImage={showImage} imagePosition={imagePosition} orientation={orientation}/>
                </ImageContainer>
                <ImageContainer>
                    <Image src={img2} showImage={showImage2} imagePosition={imagePosition2} orientation={orientation}/>
                </ImageContainer>

                <ImagePreloader src={nextImg} width={1} height={1}/>

                <Section id="leadIn" style={{height: "40vh"}} show={currentScene === "leadIn"}/>

                {
                    content.map(item => {
                        return <Section key={item.sectionId} id={item.sectionId} style={{height: item.height || "auto"}}
                                        show={currentScene === item.sectionId}>
                            {item.content}
                        </Section>
                    })
                }
            </div>
            }
        </React.Fragment>;
    }

}