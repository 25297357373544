import React from "react";
import styled, {css} from "styled-components";
import {
  TextOnlyContainer,
  Text, OuterTextContainer,
} from "../components/styledcomponents";
import ReactAudioPlayer from "react-audio-player";
import Portrait from "../media_about/Foto_AT__Rubrik_About.jpg";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';


const ImprintContainer = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-Y: scroll;
  background-color: #fff !important;
  color: #333 !important;
  paddingTop: 100;
  paddingBottom: 100;
`;

export class About extends React.Component {

  render() {
    return (
      <ImprintContainer>
        <OuterTextContainer>
          <TextOnlyContainer>
            <Text black xl underlined>Der Fotograf</Text>
            <Text black bottompadding>
              <img src={Portrait} style={{maxWidth: window.innerWidth < 450 ? "100%" : 450 }} />
              <br/><br/>
              <ReactAudioPlayer
                src={window.location.origin + "/audio/41_20211213_Begruessung_AT.m4a"}
                controls
                controlsList="nodownload"
              />
              <br/><br/><b>Andreas Teichmann</b> (*1970) lebt mit seiner Familie in Essen. Er hat an der Folkwang Universität der Künste studiert und ist seit 1994 als selbstständiger Fotograf tätig. Mit Leidenschaft und Nachhaltigkeit arbeitet er an Projekten, die den Menschen und seine Umwelt untersuchen.
              <br/><br/>Er hat über die letzten drei Jahrzehnte zahlreiche Publikationen und Auszeichnungen im In- und Ausland bekommen. Darunter die Teilnahme an der World Press Masterclass und dem Hasselblad Master. Er ist Mitglied der Agentur laif Photos & Reportagen, freelens und der DGPh.
              <br/><br/>„In 101 Tagen habe ich Deutschland zweimal durchquert: Ich habe mir für die täglich zufälligen Begegnungen Zeit genommen, zugehört und fotografiert. Um genau hinzusehen, habe ich ein langsames aber hochauflösendes Kamerasystem benutzt. Diese Bilder und Geschichten habe ich während meiner Wanderungen auf meinem Blog <a href="http://www.50days.de" target="_blank">www.50days.de</a> veröffentlicht.“

              <br/><br/><a href={"http://www.andreasteichmann.de/"} target={"_blank"}>www.andreasteichmann.de</a>
              <br/><a href={"https://www.instagram.com/andreas.teichmann/"} target={"_blank"}>Instagram: andreas.teichmann</a>
            </Text>

            <Text black xl underlined>Radio und TV</Text>
            <Text black bottompadding>
              <br/><br/><b>Museum Norderstedt</b>
              <br/>Podcast zur  Ausstellung 28.11.2021
              <br/><br/>
              <ReactAudioPlayer
                src={window.location.origin + "/audio/Museum_Norderstedt_Podcast_zur_Ausstellung.m4a"}
                controls
                controlsList="nodownload"
              />

              <br/><br/><b>ExtraRadio, Interview</b>
              <br/>06.04.2019
              <br/><br/>
              <ReactAudioPlayer
                src={window.location.origin + "/audio/ExtraRadio_Interview_Teichmann.m4a"}
                controls
                controlsList="nodownload"
              />

              <br/><br/><b>WDR Lokalzeit</b>
              <br/>17.01.2019
              <br/><br/>
              <Video>
                <source src={window.location.origin + "/audio/WDR_Lokalzeit_Ausstellung_DurchDeutschland_Dueren.mp4"} type="video/mp4" />
              </Video>

              <br/><br/><b>SWR2, Ausstellungskritik von Andreas Langen</b>
              <br/>11.10.2017
              <br/><br/>
              <ReactAudioPlayer
                src={window.location.origin + "/audio/SWR2_Ausstellungskritik.m4a"}
                controls
                controlsList="nodownload"
              />

            </Text>

            <Text black xl underlined>Print</Text>
            <Text black bottompadding>
              <b>Hamburger Abendblatt, Nov./Dez. 2021 </b>
              <br/><a href={window.location.origin + "/pdf/20211124_HamburgerAbendblatt.pdf"} target={"_blank"}>PDF</a>
            </Text>

            <Text black bottompadding>
              <b>GEO-Magazin Nr.08, 2020</b>
              <br/><a href={window.location.origin + "/pdf/GEO-Magazin-Nr-08.pdf"} target={"_blank"}>PDF</a>
            </Text>

            <Text black bottompadding>
              <b>GEO-Wissen Nr.67, 2020</b>
              <br/><a href={window.location.origin + "/pdf/GEO-Wissen-Nr-67.pdf"} target={"_blank"}>PDF</a>
            </Text>

            <Text black bottompadding>
              <b>GEO-Saison Interview 01/2020</b>
              <br/><a href={window.location.origin + "/pdf/GEO-Saison-Interview-01-2020.pdf"} target={"_blank"}>PDF</a>
            </Text>

            <Text black bottompadding>
              <b>Photonews 11/2018</b>
              <br/><a href={window.location.origin + "/pdf/Photonews-11-2018.jpg"} target={"_blank"}>PDF</a>
            </Text>

            <Text black bottompadding>
              <b>Lift-Stadtmagazin 11/2018</b>
              <br/><a href={window.location.origin + "/pdf/Lift-Stadtmagazin-11-2018.pdf"} target={"_blank"}>PDF</a>
            </Text>

            <Text black bottompadding>
              <b>Stuttgarter Zeitung, 27.10.2018</b>
              <br/><a href={window.location.origin + "/pdf/Stuttgarter-Zeitung-2018.pdf"} target={"_blank"}>PDF</a>
            </Text>

            <Text black bottompadding>
              <b>chrismon 03/2018</b>
              <br/><a href={window.location.origin + "/pdf/chrismon-03-2018.pdf"} target={"_blank"}>PDF</a>
            </Text>

            <Text black xl underlined>Ausstellungen</Text>
            <Text black bottompadding>
              <b>Wanderung 2017+2019, Einzelausstellung</b>
              <br/>Stadtmuseum Norderstedt
              <br/>28.11.21 - 20.02.22
              <br/><a href="https://www.norderstedt.de/Kultur-und-Freizeit/Veranstaltungen/Veranstaltungskalender/Durch-Deutschland.php?object=tx,3223.4.1&ModID=11&FID=3224.584.1&NavID=1087.105.1&La=1" target={"_blank"}>Website Norderstedt</a>
            </Text>

            <Text black bottompadding>
              <b>Wanderung 2017, Einzelausstellung</b>
              <br/>2019 Internationale Photoszene Köln
              <br/>laif Photogalerie 04.05. - 12.05.2019
              <br/><a href="https://festival2019.photoszene.de/Festival/durch-deutschland/429.html" target={"_blank"}>Website Festival Photoszene 2019</a>
            </Text>

            <Text black bottompadding>
              <b>Kunsthaus Langenberg</b>
              <br/>17.03 - 28.04.2019
            </Text>

            <Text black bottompadding>
              <b>Nadelfabrik, Aachen</b>
              <br/>17.02.- 08.03.2019
            </Text>

            <Text black bottompadding>
              <b>Schloss Burgau, Düren</b>
              <br/>16.12.18 - 20.01.19
            </Text>

            <Text black bottompadding>
              <b>vhs-photogalerie Stuttgart</b>
              <br/>11.10. - 25.11.2018
              <br/><a href="https://vhs-stuttgart.de/programm/kultur-und-gestalten/ausstellungen/archiv-vhs-photogalerie" target={"_blank"}>Website Archiv der VHS Stuttgart</a>
            </Text>

          </TextOnlyContainer>
        </OuterTextContainer>
      </ImprintContainer>
    );
  }
}