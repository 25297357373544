export const menuStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    right: '36px',
    top: '36px',
    outline: "none",
  },
  bmBurgerBars: {
    background: '#aaa'
  },
  bmBurgerBarsHover: {
    background: '#aaa'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    outline: "none"
  },
  bmCross: {
    background: '#aaa',
    outline: "none"
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    outline: "none"
  },
  bmMenu: {
    background: '#222',
    padding: '60px 20px 0',
    fontSize: '18px',
    outline: "none",
    width: 500
  },
  bmMorphShape: {
    fill: '#aaa',
    outline: "none"
  },
  bmItemList: {
    padding: '0.8em',
    outline: "none"
  },
  bmItem: {
    display: 'block',
    padding: "10px",
    color: "#aaa",
    outline: "none",
    cursor: "pointer"
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.4)',
  },
}