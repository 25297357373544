import React from "react";
import ReactAudioPlayer from 'react-audio-player';
import {OuterTextContainer, TextContainer, Text} from "../components/styledcomponents";

import titel from "../img/20170806_44day_2124AT.jpg";
import titel_mobile from "../img/01_Mobilversion_Web_Q15_20170806_44day_2124AT.jpg.jpg";

import karte01 from "../img/Karte1.png";
import karte02 from "../img/Karte2.png";

import image01 from "../img/20170806_01day_0012AT.jpg";
import image02 from "../img/20170806_01day_0028AT.jpg";
import image03 from "../img/20170806_01day_0030AT.jpg";
import image04 from "../img/20170806_02day_0049AT.jpg";
import image05 from "../img/20170806_02day_0090AT.jpg";
import image06 from "../img/20170806_03day_0112AT.jpg";
import image07 from "../img/20170806_04day_0192AT.jpg";
import image08 from "../img/20170806_04day_0183AT.jpg";
import image09 from "../img/20170806_06day_0245AT.jpg";
import image10 from "../img/20170806_08day_0283AT.jpg";

import image11 from "../img/20170806_10day_0408AT.jpg";
import image12 from "../img/20170806_11day_0495AT.jpg";
import image13 from "../img/20170806_15day_0627AT.jpg";
import image14 from "../img/20170806_18day_0785AT.jpg";
import image15 from "../img/20170806_18day_0846AT.jpg";
import image16 from "../img/20170806_19day_0902AT.jpg";
import image17 from "../img/20170806_22day_1083AT.jpg";
import image18 from "../img/20170806_23day_1230AT.jpg";
import image19 from "../img/20170806_26day_1351AT.jpg";
import image20 from "../img/20170806_27day_1391AT.jpg";

import image21 from "../img/20170806_29day_1460AT.jpg";
import image22 from "../img/20170806_30day_1487AT.jpg";
import image23 from "../img/20170806_31day_1578AT.jpg";
import image24 from "../img/20170806_32day_1603AT.jpg";
import image25 from "../img/20170806_32day_1631AT.jpg";
import image26 from "../img/20170806_35day_1779AT.jpg";
import image27 from "../img/20170806_36day_1808AT.jpg";
import image28 from "../img/20170806_37day_1859AT.jpg";
import image29 from "../img/20170806_39day_1929AT.jpg";
import image30 from "../img/20170806_40day_1969AT.jpg";

import image31 from "../img/20170806_41day_1988AT.jpg";
import image32 from "../img/20170806_42day_2016AT.jpg";
import image33 from "../img/20170806_44day_2094AT.jpg";
import image34 from "../img/20170806_44day_2124AT.jpg";
import image35 from "../img/20170806_44day_2171AT.jpg";
import image36 from "../img/20170806_45day_2208AT.jpg";
import image37 from "../img/20170806_46day_2229AT.jpg";
import image38 from "../img/20170806_47day_2266AT.jpg";
import image39 from "../img/20170806_49day_2350AT.jpg";
import image40 from "../img/20170806_49day_2380AT.jpg";

import image41 from "../img/20170806_49day_2385AT.jpg";
import image42 from "../img/20170806_50day_2412AT.jpg";
import image43 from "../img/20170806_50day_2426AT.jpg";

import image44 from "../img/20190805_50Days2019_Tag01_0027AT.jpg";
import image45 from "../img/20190805_50Days2019_Tag01_0041AT.jpg";
import image46 from "../img/20190805_50Days2019_Tag02_0054AT.jpg";
import image47 from "../img/20190805_50Days2019_Tag02_0096AT.jpg";
import image48 from "../img/20190805_50Days2019_Tag03_0117AT.jpg";
import image49 from "../img/20190805_50Days2019_Tag04_0148AT.jpg";
import image50 from "../img/20190805_50Days2019_Tag05_0195AT.jpg";

import image51 from "../img/20190805_50Days2019_Tag06_0256AT.jpg";
import image52 from "../img/20190805_50Days2019_Tag07_0279AT.jpg";
import image53 from "../img/20190805_50Days2019_Tag07_0309AT.jpg";
import image54 from "../img/20190805_50Days2019_Tag09_0371AT.jpg";
import image55 from "../img/20190805_50Days2019_Tag10_0445AT.jpg";
import image56 from "../img/20190805_50Days2019_Tag10_0492AT.jpg";
import image57 from "../img/20190805_50Days2019_Tag11_0513AT.jpg";
import image58 from "../img/20190805_50Days2019_Tag12_0548AT.jpg";
import image59 from "../img/20190805_50Days2019_Tag12_0605AT.jpg";
import image60 from "../img/20190805_50Days2019_Tag13_0623AT.jpg";

import image61 from "../img/20190805_50Days2019_Tag14_0636AT.jpg";
import image62 from "../img/20190805_50Days2019_Tag14_0655AT.jpg";
import image63 from "../img/20190805_50Days2019_Tag14_0696AT.jpg";
import image64 from "../img/20190805_50Days2019_Tag15_0744AT.jpg";
import image65 from "../img/20190805_50Days2019_Tag15_0761AT.jpg";
import image66 from "../img/20190805_50Days2019_Tag17_0846AT.jpg";
import image67 from "../img/20190805_50Days2019_Tag18_0855AT.jpg";
import image68 from "../img/20190805_50Days2019_Tag19_0921AT.jpg";
import image69 from "../img/20190805_50Days2019_Tag20_1007AT.jpg";
import image70 from "../img/20190805_50Days2019_Tag21_1030AT.jpg";

import image71 from "../img/20190805_50Days2019_Tag21_1044AT.jpg";
import image72 from "../img/20190805_50Days2019_Tag23_1104AT.jpg";
import image73 from "../img/20190805_50Days2019_Tag23_1147AT.jpg";
import image74 from "../img/20190805_50Days2019_Tag24_1154AT.jpg";
import image75 from "../img/20190805_50Days2019_Tag25_1209AT.jpg";
import image76 from "../img/20190805_50Days2019_Tag26_1277AT.jpg";
import image77 from "../img/20190805_50Days2019_Tag28_1449AT.jpg";
import image78 from "../img/20190805_50Days2019_Tag29_1519AT.jpg";
import image79 from "../img/20190805_50Days2019_Tag31_1549AT.jpg";
import image80 from "../img/20190805_50Days2019_Tag31_1564AT.jpg";

import image81 from "../img/20190805_50Days2019_Tag32_1582AT.jpg";
import image82 from "../img/20190805_50Days2019_Tag33_1644AT.jpg";
import image83 from "../img/20190805_50Days2019_Tag33_1652AT.jpg";
import image84 from "../img/20190805_50Days2019_Tag33_1658AT.jpg";
import image85 from "../img/20190805_50Days2019_Tag34_1676AT.jpg";
import image86 from "../img/20190805_50Days2019_Tag36_1739AT.jpg";
import image87 from "../img/20190805_50Days2019_Tag39_1845AT.jpg";
import image88 from "../img/20190805_50Days2019_Tag40_1899AT.jpg";
import image89 from "../img/20190805_50Days2019_Tag40_1920AT.jpg";
import image90 from "../img/20190805_50Days2019_Tag41_1967AT.jpg";
import image91 from "../img/20190805_50Days2019_Tag42_1998AT.jpg";

import image92 from "../img/20190805_50Days2019_Tag43_2054AT.jpg";
import image93 from "../img/20190805_50Days2019_Tag43_2092AT.jpg";
import image94 from "../img/20190805_50Days2019_Tag44_2097AT.jpg";
import image95 from "../img/20190805_50Days2019_Tag45_2157AT.jpg";
import image96 from "../img/20190805_50Days2019_Tag46_2228AT.jpg";
import image97 from "../img/20190805_50Days2019_Tag49_2342AT.jpg";
import image98 from "../img/20190805_50Days2019_Tag51_2431AT.jpg";
import image99 from "../img/20190805_50Days2019_Tag51_2443AT.jpg";


const sectionDefaultHeight = "140vh";

export const content = [
  {
    sectionId: "Start",
    height: sectionDefaultHeight,
    image: window.innerWidth > 1200 ? titel : titel_mobile,
    content:
      <OuterTextContainer right><TextContainer>
        <Text xl underlined>Durch Deutschland</Text>
        <Text tall bottompadding>Zwei Wanderungen in 101 Tagen</Text>
        {/*<img src={titel} width="100%" />*/}
      </TextContainer></OuterTextContainer>
  },
  // {
  //   sectionId: "TXT01",
  //   content:
  //     <OuterTextContainer>
  //       <TextOnlyContainer>
  //         <Text tall black bottompadding>
  //           Im Jahr 2020 hat sich das Leben der Menschen gravierend verändert. Die Folgen des Coronavirus sind eine globale Erfahrung.
  //           <br/><br/>Mit Beginn der Ausgangsbeschränkungen begrenzte sich mein soziales Leben auf unser Zuhause, das ich mit meiner Frau und unseren Kindern bewohne. Ich kann mich nicht erinnern, jemals so viel Zeit am Stück zusammen mit meiner Familie an diesem einen Ort verbracht zu haben.
  //           <br/><br/>Das verändert die Wahrnehmung.
  //           <br/><br/>In den ersten Tagen war ich in einer Art persönlicher Schockstarre und musste mich als Fotograf erst neu orientieren. Bilder von leeren Innenstädten und Toilettenpapier-Regalen waren für mich nur objektive Symptome der aktuellen Lage.
  //           <br/><br/>Mich interessierte, wie sich andere Menschen im Lockdown mit dieser neuen Lebenswirklichkeit verhalten und ob es sie verändert.
  //         </Text>
  //         <Text xl black>Bleibt der Mensch ein soziales Wesen?</Text>
  //         <Text tall black>
  //           Diese Frage fotografisch zu untersuchen, war organisatorisch aufwändig und ich benötigte viel Zeit für Vorgespräche sowie eine umfangreiche Schutzausrüstung, um mich und die Menschen, die ich aufsuchte, zu schützen. Erst waren es private Kontakte, die mir Zugang gewährten, dann bin ich über Kirchen, soziale Verbände, Schulen, Kitas, Altenheime und Kliniken mit Menschen zusammen gekommen, für die Social Distancing zu unterschiedlichen „neuen Normalitäten“ geführt hat.
  //           <br/><br/>Der Begriff „Social Distancing“ ist irreführend. Es müsste „Physical Distancing“ heißen - sozialer Austausch findet nun alternativ im Netz und in Social Media statt.
  //           <br/><br/>Ich habe beobachtet, dass digitale Kommunikation in dieser Situation eine gutes, helfendes Mittel ist, die Erfahrung von körperlicher Nähe ersetzt sie aber niemals.
  //           <br/><br/>Es sind kurze Momente, die die emotionale Bandbreite von menschlichen Beziehungen skizzieren.
  //           <br/><br/><i>Andreas Teichmann, Fotograf, 2020</i>
  //           <br/><br/>Eine fremde Person via Videokonferenz in sein Innerstes blicken zu lassen, Ängste, Trauer, Freude, Wut, Unsicherheit zu offenbaren in einer Situation, die Menschen und Gesellschaften auf der ganzen Welt zu überfordern scheint: Das erfordert Mut. Den beweisen die Portraitierten. Und sie richten den Blick nicht nur auf die Vergangenheit und die Gegenwart, sondern blicken nach vorn. Erkennen in der Ausnahmesituation auch Momente der Nähe, die in ihnen Dankbarkeit hervorruft und sie stärkt für die Zukunft. Damit senden sie Hoffnung in die Welt.
  //           <br/><br/>Ihre Geschichten sind Schlaglichter. Beispiele für Geschichten und Gefühle, die Corona in jedes Menschenleben schreibt – und die die These dieses Projekts bestätigen:
  //           <br/><br/>Der Mensch bleibt ein soziales Wesen.
  //           <br/><br/><i>Kerstin Wördehoff und Liliane Zuuring, Autorinnen, 2021</i>
  //           <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
  //         </Text>
  //       </TextOnlyContainer>
  //     </OuterTextContainer>
  // },
  {
    sectionId: "KARTE01",
    height: sectionDefaultHeight,
    image: karte01,
    content:
      <OuterTextContainer left><TextContainer>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG01",
    height: sectionDefaultHeight,
    image: image01,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 01 – 06.08.2017 – 13:58 Uhr – km 01 – NRW, Dreiländereck, Aachen</Text>
        <Text tall bottompadding>Am Dreiländerpunkt zwischen Belgien, Deutschland und den Niederlanden macht eine Familie auf der deutschen Seite Erinnerungsbilder.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG02",
    height: sectionDefaultHeight,
    image: image02,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 01 – 06.08.2017 – 17:10 Uhr – km 09 – NRW, Aachen</Text>
        <Text tall bottompadding>Kathi feiert ihren 55. Geburtstag mit Familie und Freunden auf dem Hof eines Container-Depots.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG03",
    height: sectionDefaultHeight,
    image: image03,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 01 – 06.08.2017 – 17:53 Uhr – km 12 – NRW, Aachen</Text>
        <Text tall bottompadding>Auf dem Katschhof hat das „Future Lab Aachen“ einen großen Sandkasten vor dem Rathaus aufgebaut.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG04",
    height: sectionDefaultHeight,
    image: image04,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 02 – 07.08.2017 – 12:29 Uhr – km 17 – NRW, Aachen</Text>
        <Text tall bottompadding>Neubau der Yunus-Emre-Moschee der Ditib-Gemeinde in Aachen-Ost</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG05",
    height: sectionDefaultHeight,
    image: image05,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 02 – 07.08.2017 – 21:13 Uhr – km 43 – NRW, Weisweiler</Text>
        <Text tall bottompadding>Abends holt Markus Kuhlmann (links im LKW) das Stroh ein. Tagsüber arbeitet er gegenüber im Kraftwerk Weisweiler.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG06",
    height: sectionDefaultHeight,
    image: image06,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 03 – 08.08.2017 – 15:11 Uhr – km 55 – NRW, Inden</Text>
        <Text tall bottompadding>Abbau der Braunkohle durch Schaufelradbagger im Tagebau Inden.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG07",
    height: sectionDefaultHeight,
    image: image07,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 04 – 09.08.2017 – 15:25 Uhr – km 80 – NRW, Hambacher Forst</Text>
        <Text tall bottompadding>Bewohner des Wiesencamps. Die selbst gestrickte Schweinemaske hat die Porträtierte von ihrer Mutter geschenkt bekommen.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG08",
    height: sectionDefaultHeight,
    image: image08,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 04 – 09.08.2017 – 13:07 Uhr – km 77 – NRW, Morschenich</Text>
        <Text tall bottompadding>Stillgelegtes ehemaliges Autobahnstück der A4 in unmittelbarer Nähe des Braunkohletagebaus Hambach.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG09",
    height: sectionDefaultHeight,
    image: image09,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 06 – 11.08.2017 – 19:27 Uhr – km 141 – NRW, Köln</Text>
        <Text tall bottompadding>Rheinüberquerung. Auf der Deutzer Brücke hat jemand eine Kölsch-Flasche auf dem Geländer stehengelassen.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG10",
    height: sectionDefaultHeight,
    image: image10,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 08 – 13.08.2017 – 12:38 Uhr – km 170 – NRW, Untereschbach</Text>
        <Text tall bottompadding>Die Kurdin Nuray Yesil führt den „Steinenbrücker Kiosk“ mit Paketshop an 7 Tagen in der Woche.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG11",
    height: sectionDefaultHeight,
    image: image11,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 10 – 15.08.2017 – 13:25 Uhr – km 211 – NRW, Alpe</Text>
        <Text tall bottompadding>Nicht bewohntes Jagdhaus</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG12",
    height: sectionDefaultHeight,
    image: image12,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 11 – 16.08.2017 – 16:02 Uhr – km 239 – NRW, Olpe</Text>
        <Text tall bottompadding>Michael Stinn arbeitet gelegentlich als Holzfäller im Geislinger Wald. Hauptberuflich bewirtschaftet er mit seinem Vater einen Viehbetrieb.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG13",
    height: sectionDefaultHeight,
    image: image13,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 15 – 20.08.2017 – 11:35 Uhr – km 306 – NRW, Jagdhaus</Text>
        <Text tall bottompadding>Annette Schlingmann aus Hilversum in den Niederlanden besucht erstmalig das Sauerland. Sie möchte einfach mal „Berge und Wald erfahren“, ohne sonderlich weit zu reisen.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG14",
    height: sectionDefaultHeight,
    image: image14,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 18 – 23.08.2017 – 12:37 Uhr – km 365 – Hessen, Korbach</Text>
        <Text tall bottompadding>Norbert Galliger, ein pensionierter Postbeamter, pflückt sich Birnen von den Gemeindebäumen. Früher wurde das Pflückrecht noch von der Gemeinde versteigert, aber wegen mangelnder Nachfrage darf nun jedermann frei ernten.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG15",
    height: sectionDefaultHeight,
    image: image15,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 18 – 23.08.2017 – 15:55 Uhr – km 371 – Hessen, Korbach</Text>
        <Text tall bottompadding>Eine Familie ist auf dem Weg zum Hausarzt in der Altstadt von Korbach. Im Hintergrund die Fassade des Wolfgang-Bonhage-Museums.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG16",
    height: sectionDefaultHeight,
    image: image16,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 19 – 24.08.2017 – 19:08 Uhr – km 397 – Hessen, Bad Emstal</Text>
        <Text tall bottompadding>Alte Reifen auf einem Feld in der Nähe des Dorfes.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG17",
    height: sectionDefaultHeight,
    image: image17,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 22 – 27.08.2017 – 10:22 Uhr – km 432 – Hessen, Kassel</Text>
        <Text tall bottompadding>Michael Szecesenyi lebt seit 1961 in der Nordstadt. Die Documenta interessiert ihn nicht, nur Beuys hat ihm damals gefallen: „Der hat wenigstens Bäume gepflanzt“.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG18",
    height: sectionDefaultHeight,
    image: image18,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 23 – 28.08.2017 – 09:49 Uhr – km 443 – Hessen, Kassel</Text>
        <Text tall bottompadding>„Das Parthenon der Bücher“ von der Künstlerin Marta Minujin auf dem Friedrichsplatz zur Documenta 14. Die Installation besteht aus ca. 100.000 Büchern, die verboten waren oder es sogar noch sind.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG19",
    height: sectionDefaultHeight,
    image: image19,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 26 – 28.08.2017 – 09:49 Uhr – km 508 – Thüringen, Uder</Text>
        <Text tall bottompadding>Ortsmitte. Nach genau 25 Tagen habe ich die neuen Bundesländer erreicht.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG20",
    height: sectionDefaultHeight,
    image: image20,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 27 – 01.09.2017 – 14:07 Uhr – km 535 – Thüringen, Worbis</Text>
        <Text tall bottompadding>Andreas Kunze hat gerade seine Ausbildung zum Traktorist als Klassenbester abgeschlossen.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG21",
    height: sectionDefaultHeight,
    image: image21,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 29 – 03.09.2017 – 15:58 Uhr – km 583 – Thüringen, Schloss Sondershausen</Text>
        <Text tall bottompadding>Rolf und Rotraud Hort sind 1972 aus der DDR in die BRD geflüchtet. Vor zwei Jahren sind sie samt Dackel Hilde in ihre alte Heimat nach Erfurt zurückgezogen und machen seitdem wöchentlich Ausflüge, um Thüringen wieder kennenzulernen.
        </Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG22",
    height: sectionDefaultHeight,
    image: image22,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 31 – 05.09.2017 – 14:07 Uhr – km 619 – Thüringen, Heldrungen</Text>
        <Text tall bottompadding>Das alte Schützenhaus wird nach langem Leerstand abgerissen. Nachbarn beobachten den Abriss. Thüringen fördert die Maßnahme aus dem Programm „Anpassung an den demografischen Wandel“.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG23",
    height: sectionDefaultHeight,
    image: image23,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 30 – 04.09.2017 – 15:11 Uhr – km 601 – Thüringen, Seega</Text>
        <Text tall bottompadding>Auf dem Schützenplatz des Dorfes übt Emelie den „Shuffle“, einen Tanz, den sie sich durch YouTube-Tutorials selbst beigebracht hat.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG24",
    height: sectionDefaultHeight,
    image: image24,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 32 – 06.09.2017 – 17:06 Uhr – km 659 – Sachsen-Anhalt, Nebra-Wangen</Text>
        <Text tall bottompadding>Roland Tscherny, Sprenghauer im Steinsalz-Bergbau, hat die Nachbildung von Schacht Georg aus seinem Dorf selbst gebaut. Der rote Stern leuchtete immer, wenn der Zechen-Jahresplan erfüllt war. Dann gab es Schachtschnaps für alle Bergleute, Minderjährige bekamen Schokolade (die aber nicht Schachtschokolade hieß).</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG25",
    height: sectionDefaultHeight,
    image: image25,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 32 – 06.09.2017 – 19:58 Uhr – km 671 – Sachsen-Anhalt, Karsdorf</Text>
        <Text tall bottompadding>Ein Gewitter zieht auf. Sanierungsarbeiten an der Nebraer Straße. Die Unstruttalbrücke, mit 2668 Metern die zweitlängste Eisenbahnüberführung in Deutschland, gehört zur neuen Hochgeschwindigkeitsverbindung Berlin–München.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG26",
    height: sectionDefaultHeight,
    image: image26,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 35 – 09.09.2017 – 18:15 Uhr – km 738 – Sachsen, Leipzig</Text>
        <Text tall bottompadding>Tim und Mario feiern mit ihren Gästen ihre heutige Hochzeit bzw. eingetragene Lebenspartnerschaft.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG27",
    height: sectionDefaultHeight,
    image: image27,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 36 – 10.09.2017 – 14:40 Uhr – km 748 – Sachsen, Leipzig</Text>
        <Text tall bottompadding>Claudia und Florian haben sich vor sechs Monaten über ein Internet-Partnerportal kennengelernt. Dieser Teil des Augustus-Platzes wurde zwei Tage zuvor in Kurt-Masur-Platz umbenannt. Kurt Masur war von 1970 bis 1997 Kapellmeister im Leipziger Gewandhaus und im Herbst 1989 Mitverfasser des Aufrufs „Keine Gewalt“.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG28",
    height: sectionDefaultHeight,
    image: image28,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 37 – 11.09.2017 – 15:19 Uhr – km 760 – Sachsen, Leipzig</Text>
        <Text tall bottompadding>Sascha Bärwald wohnt seit einem Jahr in der Rüdigerstraße, die jetzt mit Fernwärme aus den grünen Rohren beheizt wird. Vorher hatte er immer Wohnungen mit Kohleheizung.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG29",
    height: sectionDefaultHeight,
    image: image29,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 39 – 13.09.2017 – 11:16 Uhr – km 796 – Sachsen, Grimma</Text>
        <Text tall bottompadding>Zwei extreme Hochwasser 2002 und 2013 haben in der Altstadt zahlreiche Häuser zerstört. Die verschließbaren Hochwassertore sollen vor zukünftigen Überschwemmungen schützen.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG30",
    height: sectionDefaultHeight,
    image: image30,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 40 – 14.09.2017 – 15:46 Uhr – km 825 – Sachsen, Großweitzschen</Text>
        <Text tall bottompadding>White-Galloway-Rinder auf einer Weide.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG31",
    height: sectionDefaultHeight,
    image: image31,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 41 – 15.09.2017 – 11:15 Uhr – km 838 – Sachsen, Döbeln</Text>
        <Text tall bottompadding>Jens Lidzba angelt Karpfen in der Freiberger Mulde, dem schnellstfließenden Fluss Europas.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG32",
    height: sectionDefaultHeight,
    image: image32,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 42 – 16.09.2017 – 15:57 Uhr – km 874 – Sachsen, Mohorn</Text>
        <Text tall bottompadding>Dirk Jähniche ist Freizeitpilot. Er betankt sein umgebautes ehemaliges Agrar-Ultraleichtflugzeug, eine Avid Flyer Mark IV Speedwing.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG33",
    height: sectionDefaultHeight,
    image: image33,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 44 – 18.09.2017 – 15:36 Uhr – km 908 – Sachsen, Dresden</Text>
        <Text tall bottompadding>Frau Herklotz kommt jeden Montag in die öffentliche Zentralbibliothek, um die Fußballberichte vom Wochenende zu lesen.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG34",
    height: sectionDefaultHeight,
    image: image34,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 44 – 18.09.2017 – 17:46 Uhr – km 910 – Sachsen, Dresden</Text>
        <Text tall bottompadding>Hung und seine Frau Trang lassen Hochzeitsbilder vor der klassischen Ansicht von Dresdens Altstadt machen. Die beiden Vietnamesen leben schon seit 20 Jahren in Deutschland und betreiben jetzt ein Restaurant in Leipzig.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG35",
    height: sectionDefaultHeight,
    image: image35,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 44 – 18.09.2017 – 18:47 Uhr – km 911 – Sachsen, Dresden</Text>
        <Text tall bottompadding>Letzte PEGIDA-Demonstration vor der Bundestagswahl 2017. Die Teilnehmer sammeln sich auf dem Neumarkt vor der Frauenkirche.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG36",
    height: sectionDefaultHeight,
    image: image36,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 45 – 19.09.2017 – 18:31 Uhr – km 940 – Sachsen, Dürrröhrsdorf-Dittersbach</Text>
        <Text tall bottompadding>Strommasten aus Holz auf einem gepflügten Feld.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG37",
    height: sectionDefaultHeight,
    image: image37,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 46 – 20.09.2017 – 13:38 Uhr – km 952 – Sachsen, Hohburkersdorf</Text>
        <Text tall bottompadding>Aussicht vom „Rundblick“ auf einen durchziehenden Regenschauer.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG38",
    height: sectionDefaultHeight,
    image: image38,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 47 – 21.09.2017 – 11:15 Uhr – km 965 – Sachsen, Neustadt</Text>
        <Text tall bottompadding>Hinweisschild am Stadtrand.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG39",
    height: sectionDefaultHeight,
    image: image39,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 49 – 23.09.2017 – 11:00 Uhr – km 1015 – Sachsen, Seifhennersdorf</Text>
        <Text tall bottompadding>Eine tschechische Landesecke grenzt an das gemähte deutsche Staatsgebiet.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG40",
    height: sectionDefaultHeight,
    image: image40,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 49 – 23.09.2017 – 12:05 Uhr – km 1019 – Sachsen, Hainewalde</Text>
        <Text tall bottompadding>Kristin Heinz sammelt gern Pilze während ihres täglichen Spazierganges durch den Wald.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG41",
    height: sectionDefaultHeight,
    image: image41,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 49 – 23.09.2017 – 13:44 Uhr – km 1023 – Sachsen, Hörnitz</Text>
        <Text tall bottompadding>Celine Freund hat sich die Sehne am Fuß gerissen. Sie wartet an der Landstraße auf den Bus nach Zittau.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG42",
    height: sectionDefaultHeight,
    image: image42,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 50 – 24.09.2017 – 11:08 Uhr – km 1030 – Sachsen, Zittau</Text>
        <Text tall bottompadding>Wahllokal der Bundestagswahl 2017 im historischen Bürgersaal des Rathauses von Zittau.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG43",
    height: sectionDefaultHeight,
    image: image43,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 50 – 24.09.2017 – 12:22 Uhr – km 1033 – Sachsen, Zittau</Text>
        <Text tall bottompadding>Friedelind und Jürgen Keinert aus Chemnitz besuchen erstmalig das Dreiländereck zwischen Deutschland, Polen, Tschechien. Ihre Kinder haben ihnen das Wochenende in Zittau zum Geburtstag geschenkt.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "KARTE02",
    height: sectionDefaultHeight,
    image: karte02,
    content:
      <OuterTextContainer left><TextContainer>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG44",
    height: sectionDefaultHeight,
    image: image44,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 01 – 05.08.2019 – 13:16 Uhr – km 02 – Bayern, Oberallgäu, Haldenwanger Eck</Text>
        <Text tall bottompadding>Kerstin und Manfred Hübner aus Zwickau wandern gern in den Alpen und sind erstmalig am südlichsten Punkt Deutschlands. Nach der Wende wurde dann mit dem Nachwuchs durch Europa geflogen. Jetzt, wo die Kinder groß sind, fokussieren sie sich auf die alten Bundesländer.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG45",
    height: sectionDefaultHeight,
    image: image45,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 01 – 05.08.2019 – 16:28 Uhr – km 08 – Bayern, Oberallgäu, Rappenalptal</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/01_20190805_Sennerin_.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Der Bierausschank in der „Schwarzen Hütte“ (1242 m ü. M.). Die Witwe Hedwig Bietsch ist im Sommer Hüttenwirtin und im Winter Hausfrau. Sie lebt dieses Jahr den letzten Sommer auf der Hütte.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG46",
    height: sectionDefaultHeight,
    image: image46,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 02 – 06.08.2019 – 10:14 Uhr – km 13 – Bayern, Oberallgäu</Text>
        <Text tall bottompadding>Blick in den Regen bei Einödsbach.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG47",
    height: sectionDefaultHeight,
    image: image47,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 02 – 06.08.2019 – 15:38 Uhr – km 24 – Bayern, Oberstdorf</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/02_20190806_GerhardZeeb_03.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Gerhard Zeeb hat heute vier Festmeter Holz gespalten. Der Holzschuppen ist seit Generationen im Familienbesitz, wird aber schon länger nicht mehr für die Heulagerung genutzt.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG48",
    height: sectionDefaultHeight,
    image: image48,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 03 – 07.08.2019 – 18:30 Uhr – km 54 – Bayern, Immenstadt im Allgäu</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/03_20190807_Friederike_Moertl_01.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Die neunzigjährige Friederike Mörtl in ihrer Stube. Sie kam 1946 als Flüchtling mit ihren Eltern aus Böhmen im heutigen Tschechien. Seit ihr Vater vor 30 Jahren starb, führt sie bis heute eine Pension in ihrem Elternhaus.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG49",
    height: sectionDefaultHeight,
    image: image49,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 04 – 08.08.2019 – 13:54 Uhr – km 59 – Bayern, Luitharz im Allgäu</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/04_20190808_Leo_Bestler.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Leo Bestler geht bald in den Ruhestand und wird das in Familientradition geführte Sägewerk mangels Nachfolge und Nachfrage schließen müssen.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG50",
    height: sectionDefaultHeight,
    image: image50,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 05 – 09.08.2019 – 16:24 Uhr – km 94 – Bayern, Lauben im Allgäu</Text>
        <Text tall bottompadding>Die Großeltern Rosemarie und Peter Kalus machen mit ihren Enkelkindern einen Ausflug zum Freizeitpark „Illerparadies“. Das Gelände aus den 70er Jahren wurde dieses Jahr wieder reaktiviert.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG51",
    height: sectionDefaultHeight,
    image: image51,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 06 – 10.08.2019 – 17:36 Uhr – km 124 – Bayern, Memmingen</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/05_20190810_JungesellinenAbschied.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Auf dem Schrannenplatz feiern die Freundinnen mit Melanie ihren Junggesellinnenabschied. Zufällige Passanten werden aufgefordert, sich im „Pimmelwerfen“ zu versuchen.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG52",
    height: sectionDefaultHeight,
    image: image52,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 07 – 11.08.2019 – 11:32 Uhr – km 126 – Bayern, Memmingen</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/06_20190811_Alexander_Derksen_Basketballer.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Alexander Derksen wirft ein paar Trainingskörbe auf dem Sportplatz der Sebastian- Lotzer- Realschule. Der gebürtige Mindener ist direkt nach dem Studium wegen des alpinen Sportangebotes ins Allgäu gezogen und arbeitet hier nun als Stadtplaner.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG53",
    height: sectionDefaultHeight,
    image: image53,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 07 – 11.08.2019 – 12:50 Uhr – km 127 – Bayern, Memmingen</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/07_20190811_Afrika_Pfingskirche_01.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Sonntagsgottesdienst der freikirchlichen Pfingstgemeinde. Die meisten Mitglieder kommen aus Nigeria, Liberia, Kenia und Ghana. Ein wichtiger Teil des Gottesdienstes ist Lobpreis und Anbetung durch das gemeinsame Singen von Gebeten. </Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG54",
    height: sectionDefaultHeight,
    image: image54,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 09 – 13.08.2019 – 12:34 Uhr – km 170 – Bayern, Vöhringen</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/08_20190813_Hundehalter_Ottilie_Regina_Peter_Gelbfuessler.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Ottilie, Regina und Peter treffen sich jeden Tag für eine Mittagsrunde mit ihren Hunden: Bobby, Jenny, Beryll und Gizmo.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG55",
    height: sectionDefaultHeight,
    image: image55,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 10 – 14.08.2019 – 14:24 Uhr – km 193 – Baden-Württemberg, Ulm</Text>
        <Text tall bottompadding>Im nördlichen Seitenschiff des Ulmer Münsters, der größten evangelischen Kirche in Deutschland. Sie hat mit 161,53 m Höhe den höchsten Kirchturm der Welt.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG56",
    height: sectionDefaultHeight,
    image: image56,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 10 – 14.08.2019 – 20:11 Uhr – km 210 – Baden-Württemberg, Langenau</Text>
        <Text tall bottompadding>Ein Heißluftballon droht, im Wohngebiet an der Schammenstraße zu landen.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG57",
    height: sectionDefaultHeight,
    image: image57,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 11 – 15.08.2019 – 14:24 Uhr – km 222 – Baden-Württemberg, Dettingen am Albuch</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/09_20190815_HobbyArchaeologe_Christian_Beyer.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Christian Beyer ist Sozialpädagoge und Hobbyarchäologe. Wenn er seine Schwiegereltern auf der Schwäbischen Alb besucht, sucht er auf einem nahe gelegenen Acker nach Artefakten aus der Jungsteinzeit.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG58",
    height: sectionDefaultHeight,
    image: image58,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 12 – 16.08.2019 – 13:31 Uhr – km 236 – Baden-Württemberg, Heidenheim an der Brenz</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/10_20190816_Bildungsmassnahme_Justin_Andreas.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Asphaltarbeiten in der Bergstraße neben der Kombrecht-Engel- Schule. Justin und Andreas kennen sich aus einer berufsvorbereitenden Bildungsmaßnahme.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG59",
    height: sectionDefaultHeight,
    image: image59,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 12 – 16.08.2019 – 17:04 Uhr – km 244 – Baden-Württemberg, Königsbronn- Waldsiedlung</Text>
        <Text tall bottompadding>Väter beim Wochenendfußball mit ihren Söhnen auf dem Vereinsgelände vom HSK 05.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG60",
    height: sectionDefaultHeight,
    image: image60,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 13 – 17.08.2019 – 17:51 Uhr – km 273 – Baden-Württemberg, Ellwangen</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/11_20190817_Kurdische_Iraker_Grillen.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Am Bucher Stausee grillen die Familien Aljaf und Luqman und verbringen den Samstagabend gemeinsam. Die Familien sind vor 4 Jahren aus dem kurdischen Teil des Iraks nach Deutschland gekommen und leben nun in Heidenheim.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG61",
    height: sectionDefaultHeight,
    image: image61,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 14 – 18.08.2019 – 11:24 Uhr – km 284 – Baden-Württemberg, Ellwangen</Text>
        <Text tall bottompadding>Shaher Yar aus Pakistan und Sami Dabir aus dem Iran sind beide Teil der Sicherheitsmannschaft in der Landeserstaufnahmestelle (LEA) für im Bundes- land ankommende Asylsuchende. Es ist wichtig, möglichst viele Sprachen durch das Personal abzudecken.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG62",
    height: sectionDefaultHeight,
    image: image62,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 14 – 18.08.2019 – 12:23 Uhr – km 284 – Baden-Württemberg, Ellwangen</Text>
        <Text tall bottompadding>Mittagessenausgabe in der Mensa der Landeserstaufnahmestelle (LEA). Momentan bewohnen ca. 450 Menschen aus 20 Ländern das Gelände einer ehemaligen Kaserne und warten auf ein Bleiberecht und weitere Verteilung in Deutschland.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG63",
    height: sectionDefaultHeight,
    image: image63,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 14 – 18.08.2019 – 16:16 Uhr – km 292 – Baden-Württemberg, Stocken</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/12_20190818_Akkubohrer_Familie_Eckl_ueber_das_Leben_auf_der_SchwaebischenAlb.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Der zweijährige Anton Eckl geht mit seinen Eltern gern im Wald am Fischbach spazieren. Sein Lieblingsspielzeug, ein Akkubohrer, ist immer dabei.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG64",
    height: sectionDefaultHeight,
    image: image64,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 15 – 19.08.2019 – 17:46 Uhr – km 315 – Baden-Württemberg, Leukershausen</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/13_20190819_Stammtisch_vom_Gasthaus_ZumLamm.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Der örtliche Stammtisch kommt täglich im Gasthaus „Zum Lamm“ zusammen, um zu reden und ein Feierabendbier zu trinken.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG65",
    height: sectionDefaultHeight,
    image: image65,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 15 – 19.08.2019 – 20:00 Uhr – km 323 – Bayern, Schnelldorf</Text>
        <Text tall bottompadding>Blick auf die Photovoltaikdächer der Gemeinde Schnelldorf.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG66",
    height: sectionDefaultHeight,
    image: image66,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 17 – 21.08.2019 – 12:08 Uhr – km 349 – Bayern, Rothenburg ob der Tauber</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/14_20190821_Touristenfotos_Markplatz.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Die Touristen warten auf das täglich um zwölf Uhr stattfindende Glockenspiel am Marktplatz. Rothenburg ist eine der beliebtesten Altstadt-Touristendestinationen in Deutschland.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG67",
    height: sectionDefaultHeight,
    image: image67,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 18 – 22.08.2019 – 10:40 Uhr – km 375 – Bayern, Uffenheim</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/15_20190822_Reitverein_Uffenheim.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Reit- und Fahrverein Uffenheim, v. l. n. r.: Carolin und Hanna Lampe mit dem Haflinger „Starko“ und „Jimmy“, einem Deutschen Reitpony, Momo Ott mit dem Trakehner-Haflinger-Mix „Cooky“.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG68",
    height: sectionDefaultHeight,
    image: image68,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 19 – 23.08.2019 – 18:31 Uhr – km 417 – Bayern, Volkach</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/16_20190823_Franke_Otto_Eckert_Eisenschmelzer.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Otto Eckert ist pensionierter Eisenschmelzer und am Main aufgewachsen, wo er sich als kleiner Junge im Wasser von den Lastschiffen hat mitziehen lassen.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG69",
    height: sectionDefaultHeight,
    image: image69,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 20 – 24.08.2019 – 18:33 Uhr – km 439 – Bayern, Garstadt</Text>
        <Text tall bottompadding>Am Wohnmobilstellplatz genießen die Camper den Abend. Im Hintergrund stehen die 143 Meter hohen Kühltürme vom Kernkraftwerk Grafenrheinfeld. Es war 30 Jahre in Betrieb und ist im Juni 2015 stillgelegt worden.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG70",
    height: sectionDefaultHeight,
    image: image70,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 21 – 25.08.2019 – 11:46 Uhr – km 444 – Bayern, Grafenrheinfeld</Text>
        <Text tall bottompadding>Ein Neubau am Stadtrand. In dessen Garten bereits ein Trampolin steht und als Abgrenzung der klassische Lebensbaum (Thuja) gepflanzt ist – zwei mir oft begegnende Vorgarten-Klassiker. Im Hintergrund stehen die hohen Kühltürme vom stillgelegten Kernkraftwerk Grafenrheinfeld.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG71",
    height: sectionDefaultHeight,
    image: image71,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 21 – 25.08.2019 – 13:12 Uhr – km 446 – Bayern, Bergrheinfeld</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/17_20190825_Kirchweih_UnterfraenkischesSeniorenBlasorchester_02.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Kirchfest 2019. Im Festzelt spielt das Seniorenblasorchester Unterfranken mit einem Durchschnittsalter von 70 Jahren unter der Leitung von Erhard Rada. Die unter dem Zeltdach hängenden Bilder zeigen die „Blüapaare“ der letzten 30 Jahre. </Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG72",
    height: sectionDefaultHeight,
    image: image72,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 23 – 27.08.2019 – 14:55 Uhr – km 496 – Bayern, Hohenroth</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/18_20190827_Cafe_Steinchen_TanjaSteiner.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Tanja Steiner-Kaminsky ist Betreiberin des Cafés „Steinchen“ (deutsch für Kaminsky). Ihre Freundin Monika Graf, links neben ihr, unterstützt sie als Bäckerin. Nach 20 Jahren Arbeit als Pflegerin hat sie vor einem Jahr einen Neuanfang mit der Gründung eines Cafés gewagt.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG73",
    height: sectionDefaultHeight,
    image: image73,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 23 – 27.08.2019 – 19:14 Uhr – km 507 – Bayern, Schönau an der Brend</Text>
        <Text tall bottompadding>Blick auf die Dorfkirche St. Laurentius, die auf dem Kirchberg über der Gemeinde thront. Vorn weiden die Islandponys von Klaus Baumgärtner, einem siebzigjährigen Rentner aus dem Dorf.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG74",
    height: sectionDefaultHeight,
    image: image74,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 24 – 28.08.2019 – 15:04Uhr – km 521 – Bayern, Ginolfs</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/19_20190828_Mann_liest_Zeitung_am_See.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Basaltsee am Steinernen Haus. Ich treffe auf einen lesenden Mann, der anonym bleiben möchte. Er kommt ein paarmal im Jahr zum See, um in Ruhe in der Natur seine Lektüre zu lesen.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG75",
    height: sectionDefaultHeight,
    image: image75,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 25 – 29.08.2019 – 12:57h – km 534 – Hessen, Ehrenberg-Thaiden</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/20_20190829_Polterabendtradition_Strohpuppen_RaphaelaSchuchert.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Raphaela Schuchert hat ihren Ehemann Florian vor einer Woche geheiratet. Die Familien der beiden haben zum Polterabend die Strohpuppen aufgebaut. Eine alte Tradition auf dem Land.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG76",
    height: sectionDefaultHeight,
    image: image76,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 26 – 30.08.2019 – 14:19 Uhr – km 568 – Hessen, Point Alpha</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/21_20190830_PointAlpha_AlteZiegelei_Willi_Schuetz.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Blick auf die ehemalige DDR-Grenze in Geisa, Thüringen. Zur Zeit des Kalten Krieges war hier die Stelle, wo sich die West- und Ostarmee gegenüberstand. Der 35 m hohe „Kirmesbaum“ wurde vor einer Woche erstmalig an dieser Stelle aufgebaut und war Teil des Festes „Grenzenlos – 30 Jahre Grenzöffnung“.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG77",
    height: sectionDefaultHeight,
    image: image77,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 28 – 01.09.2019 – 15:17 Uhr – km 606 – Hessen, Obersuhl</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/22_20190901_Kirmesgesellschaft_Obersuhl.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Die Kirmes in Obersuhl findet jährlich statt. Viele Zuschauer sehen sich den traditionellen Festumzug am Sonntagnachmittag an. Am Abzweig Eisenacher Straße (Euro Döner) endet der Umzug und die Kirmes wird im Festzelt weiter- gefeiert. Die Teilnehmer bestehen aus einer Mischung von lokalen Vereinen, Firmen, Dorfbewohnern und benachbarten Kirmesgesellschaften.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG78",
    height: sectionDefaultHeight,
    image: image78,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 29 – 02.09.2019 – 19:27 Uhr – km 646 – Hessen, Eschwege-Niederhone</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/23_20190902_Geschwister_schneiden_Rosen.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Die 90-jährige Gerda Schäfer und ihre fünf Jahre jüngere Schwester Theresia Klapp pflegen gemeinsam die Rosen im Vorgarten. Sie sind die letzten Lebenden von
          elf Geschwistern, die 1945 aus Schlesien nach Hannover geflüchtet sind. Frau Schäfer hat Krankenpflegerin gelernt und bis zur Rente gearbeitet. Ihre Schwester Theresia besucht sie jährlich für drei Wochen. </Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG79",
    height: sectionDefaultHeight,
    image: image79,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 31 – 04.09.2019 – 10:42 Uhr – km 672 – Thüringen, Uder</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/24_20190903_Uder_Pizzeria_Paradies.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Die Stadtmitte von Uder. Das ist mein Strecken Schnittpunkt. Hier habe ich auch 2017 auf meiner West-Ost-Durchquerung übernachtet.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG80",
    height: sectionDefaultHeight,
    image: image80,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 31 – 04.09.2019 – 18:01 Uhr – km 697 – Niedersachsen, Göttingen</Text>
        <Text tall bottompadding>Christoph Oppermann ist Student der Technischen Informatik in Berlin und besucht in seinen Semesterferien die Eltern in Göttingen. Auf seiner Abendrunde macht er gerade Pause, um Pflaumen zu pflücken.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG81",
    height: sectionDefaultHeight,
    image: image81,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 32 – 05.09.2019 – 12:59 Uhr – km 703 – Niedersachsen, Göttingen</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/25_20190905_Goettingen_Elternzeit_Muetter.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>V. l. n. r. Bettina Bode mit ihren Kindern Edith und Valentin ist in Elternzeit. Ihre Freundin Stephanie Häusser mit ihrer Tochter Matilda arbeitet momentan an ihrer Doktorarbeit und teilt sich die Woche auf in je 50 % Arbeit und Kinderbetreuung. Beide empfinden Göttingen als einen guten Ort für junge Familien. </Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG82",
    height: sectionDefaultHeight,
    image: image82,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 33 – 06.09.2019 – 12:21 Uhr – km 728 – Niedersachsen, Northeim/ Langenholtensen</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/26_20190906_Farin_Trialfahrer.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Farin Bierwirth hat sich das Trialfahren durch YouTube-Tutorials beigebracht. Nach der Förderschule möchte er gern eine Ausbildung zum Zweiradmechaniker machen.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG83",
    height: sectionDefaultHeight,
    image: image83,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 33 – 06.09.2019 – 13:38 Uhr – km 730 – Niedersachsen, Wiebrechtshausen</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/27_20190906_Kartoffelscheune_AntjeAltenweger.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Antje Altenweger, Kartoffelbäuerin und Lehrerin, steht im Sortierraum der historischen Kartoffelscheune des Klosterguts Wiebrechtshausen.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG84",
    height: sectionDefaultHeight,
    image: image84,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 33 – 06.09.2019 – 18:10 Uhr – km 740 – Niedersachsen, Sebexen</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/28_20190906_Glasfaserkabel_Verlegung.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Die Deutsche Glasfaser verlegt Glasfaserkabel im Dorf. Am Haus „Alte Siedlung 1“ hatte der Bautrupp keine Bohrmaschine, um ein Loch durch die Mauer zu bohren. Also wurden die Leerrohre zusammen- gebunden. Die Bewohnerin des Hauses wartet und sehnt den Tag herbei, endlich Internet zu haben.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG85",
    height: sectionDefaultHeight,
    image: image85,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 34 – 07.09.2019 – 17:57 Uhr – km 768 – Niedersachsen, Bodenburg</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/29_20190907_100Jahrfeier_Bodenburg.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Das Orgateam und der Vorstand des Männerturnvereins (MTV) Bodenburg feiern heute das 100- jährige Bestehen des Vereins in der Turnhalle. Dieser repräsentiert mit über 500 MitgliederInnen fast ein Viertel der Bewohner von Bodenburg.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG86",
    height: sectionDefaultHeight,
    image: image86,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 36 – 09.09.2019 – 17:36 Uhr – km 798 – Niedersachsen, Hildesheim</Text>
        <Text tall bottompadding>Blick auf den Abzweig des Stichkanals Hildesheim, der vom Mittellandkanal (MLK) abgeht. Der MLK ist inklusive seiner Stich- und Verbindungskanäle mit 392 km die längste künstliche Wasserstraße in Deutschland.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG87",
    height: sectionDefaultHeight,
    image: image87,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 39 – 12.09.2019 – 15:15 Uhr – km 876 – Niedersachsen, Bergen-Belsen</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/30_20190912_BergenBelsen_Diego_Bernardini_ueber_das_Vergessen.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Im Dokumentationszentrum der Gedenkstätte Bergen-Belsen begegne ich Diego Bernardini. Der Schweizer besucht seit Jahren Gedenkstätten von KZs und Vernichtungslagern. „Vergessen heißt verraten“ lautet sein Credo. Heute ist er das erste Mal in Bergen-Belsen. Angefangen hat es vor 12 Jahren, da war er im KZ Buchenwald. Der Besuch hatte ihn so berührt, dass er weitere Orte aufsuchen musste.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG88",
    height: sectionDefaultHeight,
    image: image88,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 40 – 13.09.2019 – 12:24 Uhr – km 893 – Niedersachsen, Becklingen</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/31_20190913_Jaeger_ueber_die_Jagd_Zum_BecklingerHolz_.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>In der Gaststätte „Zum Becklinger Holz“ sitzen Holger Schroeter und der Förster Andreas Sälzer beim traditionellen „Tod-Trinken“ nach einer erfolgreichen Jagd beisammen. Der Jagdgast Schroeter hatte in den Morgenstunden einen alten Hirsch in der Brunft erlegt.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG89",
    height: sectionDefaultHeight,
    image: image89,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 40 – 13.09.2019 – 17:24 Uhr – km 912 – Niedersachsen, Wietzendorf</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/32_20190913_BulliTreffen_Wietzendorf.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>V. l. n. r.: Diandra, Timo, Steffen und Lea sind zufällige Campingnachbarn beim 20. VW-Bustreffen am Südsee-Camp. Sie feiern gemeinsam die Leidenschaft für VW-Busse.
          Der grüne T3 von 1979, genannt Kermit, gehört seit sechs Monaten der Bulli-Treff- Debütantin Diandra. Der schwarze 1988er T3, genannt Black Betty, ist Steffens zweiter VW-Bus. Timo ist der Fahrer für einen befreundeten blinden Bulli-Besitzer. </Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG90",
    height: sectionDefaultHeight,
    image: image90,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 41 – 14.09.2019 – 15:49 Uhr – km 932 – Niedersachsen, Bispingen</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/33_20190914_Bispingen_FamilieBockelmann_ueber_das_Landleben_und_ostfriesische_Namen.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Kathrin Bockelmann mit ihren fünf Söhnen, v. l. n. r.: Thiark, Bent, Maart, Thees und Hannek. Die Familie schaute dem Vater beim Mais häckseln zu, später suchte sich jeder der Jungs noch einen Kürbis aus. Die Namen der Söhne sind alle ostfriesischen Ursprungs. </Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG91",
    height: sectionDefaultHeight,
    image: image91,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 42 – 15.09.2019 – 16:19 Uhr – km 957 – Niedersachsen, Asendorf</Text>
        <Text tall bottompadding>Die erste Mannschaft, in den roten Trikots des TV Asendorfs, spielt ein Ligaspiel in der 3. Kreisklasse gegen die dritte Mannschaft von Fleestedt. Das Spiel endete 5:1 für den TV Asendorf.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG92",
    height: sectionDefaultHeight,
    image: image92,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 43 – 16.09.2019 – 16:59 Uhr – km 991 – Hamburg, Wilhelmsburg</Text>
        <Text tall bottompadding>Mietshäuser mit vielen Satellitenschüsseln am Ernst-August-Deich.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG93",
    height: sectionDefaultHeight,
    image: image93,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 43 – 16.09.2019 – 19:22 Uhr – km 997 – Hamburg, St. Pauli, Reeperbahn</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/34_20190916_Reeperbahn_Frauengruppe.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>V. l. n. r. Laura, Cristina, Sirka und Annika feiern ihren ersten Besuch in Hamburg. Die Freundinnen sind auf dem Weg zum Club „Große Freiheit 36“, wo der Rapper „Fetty Wap“ ein Konzert gibt. </Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG94",
    height: sectionDefaultHeight,
    image: image94,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 44 – 17.09.2019 – 14:37 Uhr – km 1012 – Schleswig-Holstein, Norderstedt</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/35_20190917_Norderstedt_Foodtruck.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Die auffällig gestalteten Bürogebäude „Nordport Towers“ sind seit 2 Wochen die Dienstags- Location für den Foodtruck „84 Grad Pork-Adillo“. Der Betreiber Roland Schmitt und sein Freund Peter Dollinger arbeiten erst seit einem Jahr mit dem Foodtruck. Peter, geboren in Mainz, begeistert sich seit der Jugend für das Kochen in all seinen Varianten.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG95",
    height: sectionDefaultHeight,
    image: image95,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 45 – 18.09.2019 – 11:55 Uhr – km 1036 – Schleswig-Holstein, Heede</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/36_20190918_Heede_Hecke_schneiden.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Der Rentner Norbert Germer trimmt seine Thujen einmal im Jahr. Er benötigt drei Tage für den kompletten Schnitt und beschreibt das Resultat als „geordnetes Chaos“.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG96",
    height: sectionDefaultHeight,
    image: image96,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 46 – 19.09.2019 – 15:43 Uhr – km 1079 – Schleswig-Holstein, Huje</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/37_20190919_Huje_Hans-Juergen_Boege_Milchbauer.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Hans-Jürgen Böge ist Milchbauer in Rente. Sein Sohn und sein Neffe arbeiten jetzt im Betrieb und sind die fünfte Generation. Früher als es noch keine Brücke über den nahegelegenen Fluss Bekau gab, hat der Urgroßvater von Hans-Jürgen die Menschen mit einer Fähre übergesetzt. Bezahlt wurde mit dem Kauf eines Getränks bei ihm.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG97",
    height: sectionDefaultHeight,
    image: image97,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 49 – 22.09.2019 – 12:11 Uhr – km 1164 – Schleswig-Holstein, Husum, Schobüll</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/38_20190922_Husum_Schobuell_Familie_Rudolph_mit_Baby.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Bei Ebbe sehen wir von der Schobüller Seebrücke direkt auf das Wattenmeer der Nordsee. Die junge Wuppertaler Familie Rudolph mit ihrer vier Monate alten Tochter Johanna verbringt dort den ersten gemeinsamen Urlaub. Sie haben sich Schobüll ausgesucht, weil es dort keine Deiche gibt und sie hier den direkten Blick auf das Meer haben.</Text>
      </TextContainer></OuterTextContainer>
  },
  {
    sectionId: "IMG98",
    height: sectionDefaultHeight,
    image: image98,
    imagePosition: "right",
    content:
      <OuterTextContainer left><TextContainer>
        <Text bottompadding underlined>Tag 51 – 24.09.2019 – 14:43 Uhr – km 1225 – Schleswig-Holstein, Sylt, Kampen</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/39_20190924_Sylt_Kampen_Ehepaar_Brase_pflegt_Vater_im_Rollstuhl.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Dagmar und Volker Brase machen Urlaub auf Sylt mit Dagmars schwer dementen Vater Horst. Er leidet an der Lungen- krankheit COPD und genießt die frische Seeluft. Sie haben sich vor Ort den Elektro-Rollstuhl „CadWeazle“ geliehen, um mit dem Vater am Strand mobil zu sein. Seit dem Tod der Mutter pflegt das Ehepaar den Vater bei sich zu Hause in Erlangen, wo noch die eigenen drei Kinder leben. Heute wäre der 59. Hochzeitstag von Dagmars Eltern gewesen.</Text>
      </TextContainer></OuterTextContainer>
  },

  {
    sectionId: "IMG99",
    height: sectionDefaultHeight,
    image: image99,
    imagePosition: "left",
    content:
      <OuterTextContainer right><TextContainer>
        <Text bottompadding underlined>Tag 51 – 24.09.2019 – 20:39 Uhr – km 1241 – Schleswig-Holstein, Sylt, List</Text>
        <Text>
          <ReactAudioPlayer
            src={window.location.origin + "/audio/40_20190924_Gedanken_am_noerdlichsten_Punklt_Deutschlands.m4a"}
            style={{outline:"none"}}
            controls
            controlsList="nodownload"
          />
        </Text>
        <Text tall bottompadding>Der Lister Ellenbogen ist ein Vogel- und Naturschutzgebiet. Es gehört einer privaten Eigentümergemeinschaft, die mit den Mauteinnahmen das Reservat unterhält. Hier liegt der nördlichste Punkt Deutschlands. Er ist markiert durch eine einfache Holztafel. Am 51. Tag erreiche ich den Punkt nach Sonnenuntergang um 20:35 Uhr. In diesem Moment bin ich der einzige Mensch dort.</Text>
      </TextContainer></OuterTextContainer>
  },
];