import React from "react";
import styled, {css} from "styled-components";
import {
  TextOnlyContainer,
  Text, OuterTextContainer,
} from "../components/styledcomponents";
// import portrait from '../img/AutorinnenPortrait_LilianeZuuring.jpg';

const ImprintContainer = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-Y: scroll;
  background-color: #fff !important;
  color: #333 !important;
  paddingTop: 100;
  paddingBottom: 100;
`;

export class LilianeZuuring extends React.Component {

  render() {
    return (
      <ImprintContainer>
        <OuterTextContainer>
          <TextOnlyContainer>
            <Text black xl underlined>Liliane Zuuring</Text>
            <Text>
            {/*<img src={portrait} width={400} />*/}
            </Text>
            <Text black style={{fontFamily: "Roboto, Arial, sans-serif"}}>
              Das Leben eines jeden Gegenübers birgt fesselnde Geschichten: Die erzählt Liliane Zuuring, Jahrgang 1970, als Redakteurin der Westdeutschen Allgemeinen Zeitung seit Jahrzehnten mit Leidenschaft. Die Mutter von drei Kindern, die Allgemeine und Vergleichende Literaturwisenschaft, Englisch und Geschichte in Essen und Pisa studierte, reizt an diesem Projekt, zu ergründen, welche Wege Menschen in der Corona-Pandemie finden, um auf Distanz Emotionen zu leben, Ängste zu bändigen und einander Halt zu geben.
              <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </Text>
          </TextOnlyContainer>
        </OuterTextContainer>
      </ImprintContainer>
    );
  }
}