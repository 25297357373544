import React from "react";
import styled, {css} from "styled-components";
import {
  TextOnlyContainer,
  Text, OuterTextContainer,
} from "../components/styledcomponents";
// import portrait from "../img/AutorinnenPortrait_KerstinWoerdehoff.jpg";

const ImprintContainer = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-Y: scroll;
  background-color: #fff !important;
  color: #333 !important;
  paddingTop: 100;
  paddingBottom: 100;
`;

export class KerstinWoerdehoff extends React.Component {

  render() {
    return (
      <ImprintContainer>
        <OuterTextContainer>
          <TextOnlyContainer>
            <Text black xl underlined>Kerstin Wördehoff</Text>
            <Text>
              {/*<img src={portrait} width={400} />*/}
            </Text>
            <Text black style={{fontFamily: "Roboto, Arial, sans-serif"}}>
              Die Schwelmerin Kerstin Wördehoff zückte bereits mit 14 Jahren den Stift für die heimische Tageszeitung. Nach einem Zeitungsvolontariat und neun Jahren als freiberufliche Redakteurin gibt die Westfälin Jahrgang 1979 heute ihr Wissen als Redakteurin und Bildungsmanagerin in einer Akademie für Journalismus und Kommunikation weiter. Das pure Leben lässt sich von einer Pandemie nicht aufhalten, es findet einfach neue Wege. Dies durch die Geschichten der Menschen auf den Bildern zu ergründen und ihre Emotionen in Sprache zu fassen, empfindet die Journalistin als besondere Bereicherung, die sie im Rahmen des Projekts erfahren durfte.
              <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </Text>
          </TextOnlyContainer>
        </OuterTextContainer>
      </ImprintContainer>
    );
  }
}