import React from 'react';
import './App.css';
import {TIGIPMainView} from "./components/TIGIPMainView";

function App() {
  return (
    <TIGIPMainView />
  );
}

export default App;
