import React from "react";
import ReactAudioPlayer from "react-audio-player";
import {
   Text, TextContainer, OuterTextContainer
} from "./styledcomponents";

export const playerContent = {
    1: {
        text: "Tag 01 – 05.08.2019 – 16:28 Uhr – km 08 – Bayern, Oberallgäu, Rappenalptal",
        text2: "Der Bierausschank in der „Schwarzen Hütte“ (1242 m ü. M.). Die Witwe Hedwig Bietsch ist im Sommer Hüttenwirtin und im Winter Hausfrau. Sie lebt dieses Jahr den letzten Sommer auf der Hütte. ",
        audio: "/audio/01_20190805_Sennerin_.m4a"
    },
    2: {
        text: "Tag 02 - 06.08.2019 – 15:38 Uhr – km 24 – Bayern, Oberstdorf",
        text2: "Gerhard Zeeb hat heute vier Festmeter Holz gespalten. Der Holzschuppen ist seit Generationen im Familienbesitz, wird aber schon länger nicht mehr für die Heulagerung genutzt.",
        audio: "/audio/02_20190806_GerhardZeeb_03.m4a"
    },
    3: {
        text: "Tag 03 – 07.08.2019 – 18:30 Uhr – km 54 – Bayern, Immenstadt im Allgäu",
        text2: "Die neunzigjährige Friederike Mörtl in ihrer Stube. Sie kam 1946 als Flüchtling mit ihren Eltern aus Böhmen im heutigen Tschechien. Seit ihr Vater vor 30 Jahren starb, führt sie bis heute eine Pension in ihrem Elternhaus.",
        audio: "/audio/03_20190807_Friederike_Moertl_01.m4a"
    },
    4: {
        text: "Tag 04 – 08.08.2019 – 13:54 Uhr – km 59 – Bayern, Luitharz im Allgäu",
        text2: "Leo Bestler geht bald in den Ruhestand und wird das in Familientradition geführte Sägewerk mangels Nachfolge und Nachfrage schließen müssen.",
        audio: "/audio/04_20190808_Leo_Bestler.m4a"
    },
    5: {
        text: "Tag 06 – 10.08.2019 – 17:36 Uhr – km 124 – Bayern, Memmingen",
        text2: "Auf dem Schrannenplatz feiern die Freundinnen mit Melanie ihren Junggesellinnenabschied. Zufällige Passanten werden aufgefordert, sich im „Pimmelwerfen“ zu versuchen.",
        audio: "/audio/05_20190810_JungesellinenAbschied.m4a"
    },
    6: {
        text: "Tag 07 – 11.08.2019 – 11:32 Uhr – km 126 – Bayern, Memmingen",
        text2: "Alexander Derksen wirft ein paar Trainingskörbe auf dem Sportplatz der Sebastian- Lotzer- Realschule. Der gebürtige Mindener ist direkt nach dem Studium wegen des alpinen Sportangebotes ins Allgäu gezogen und arbeitet hier nun als Stadtplaner.",
        audio: "/audio/06_20190811_Alexander_Derksen_Basketballer.m4a"
    },
    7: {
        text: "Tag 07 – 11.08.2019 – 12:50 Uhr – km 127 – Bayern, Memmingen",
        text2: "Sonntagsgottesdienst der freikirchlichen Pfingstgemeinde. Die meisten Mitglieder kommen aus Nigeria, Liberia, Kenia und Ghana. Ein wichtiger Teil des Gottesdienstes ist Lobpreis und Anbetung durch das gemeinsame Singen von Gebeten.",
        audio: "/audio/07_20190811_Afrika_Pfingskirche_01.m4a"
    },
    8: {
        text: "Tag 09 – 13.08.2019 – 12:34 Uhr – km 170 – Bayern, Vöhringen",
        text2: "Ottilie, Regina und Peter treffen sich jeden Tag für eine Mittagsrunde mit ihren Hunden: Bobby, Jenny, Beryll und Gizmo.",
        audio: "/audio/08_20190813_Hundehalter_Ottilie_Regina_Peter_Gelbfuessler.m4a"
    },
    9: {
        text: "Tag 11 – 15.08.2019 – 14:24 Uhr – km 222 – Baden-Württemberg, Dettingen am Albuch",
        text2: "Christian Beyer ist Sozialpädagoge und Hobbyarchäologe. Wenn er seine Schwiegereltern auf der Schwäbischen Alb besucht, sucht er auf einem nahe gelegenen Acker nach Artefakten aus der Jungsteinzeit.",
        audio: "/audio/09_20190815_HobbyArchaeologe_Christian_Beyer.m4a"
    },
    10: {
        text: "Tag 12 – 16.08.2019 – 13:31 Uhr – km 236 – Baden-Württemberg, Heidenheim an der Brenz",
        text2: "Asphaltarbeiten in der Bergstraße neben der Kombrecht-Engel-Schule. Justin und Andreas kennen sich aus einer berufsvorbereitenden Bildungsmaßnahme.",
        audio: "/audio/10_20190816_Bildungsmassnahme_Justin_Andreas.m4a"
    },
    11: {
        text: "Tag 13 – 17.08.2019 – 17:51 Uhr – km 273 – Baden-Württemberg, Ellwangen",
        text2: "Am Bucher Stausee grillen die Familien Aljaf und Luqman und verbringen den Samstagabend gemeinsam. Die Familien sind vor 4 Jahren aus dem kurdischen Teil des Iraks nach Deutschland gekommen und leben nun in Heidenheim.",
        audio: "/audio/11_20190817_Kurdische_Iraker_Grillen.m4a"
    },
    12: {
        text: "Tag 14 – 18.08.2019 – 16:16 Uhr – km 292 – Baden-Württemberg, Stocken",
        text2: "Der zweijährige Anton Eckl geht mit seinen Eltern gern im Wald am Fischbach spazieren. Sein Lieblingsspielzeug, ein Akkubohrer, ist immer dabei.",
        audio: "/audio/12_20190818_Akkubohrer_Familie_Eckl_ueber_das_Leben_auf_der_SchwaebischenAlb.m4a"
    },
    13: {
        text: "Tag 15 – 19.08.2019 – 17:46 Uhr – km 315 – Baden-Württemberg, Leukershausen",
        text2: "Der örtliche Stammtisch kommt täglich im Gasthaus „Zum Lamm“ zusammen, um zu reden und ein Feierabendbier zu trinken.",
        audio: "/audio/13_20190819_Stammtisch_vom_Gasthaus_ZumLamm.m4a"
    },
    14: {
        text: "Tag 17 – 21.08.2019 – 12:08 Uhr – km 349 – Bayern, Rothenburg ob der Tauber",
        text2: "Die Touristen warten auf das täglich um zwölf Uhr stattfindende Glockenspiel am Marktplatz. Rothenburg ist eine der beliebtesten Altstadt-Touristendestinationen in Deutschland.",
        audio: "/audio/14_20190821_Touristenfotos_Markplatz.m4a"
    },
    15: {
        text: "Tag 18 – 22.08.2019 – 10:40 Uhr – km 375 – Bayern, Uffenheim",
        text2: "Reit- und Fahrverein Uffenheim, v. l. n. r.: Carolin und Hanna Lampe mit dem Haflinger „Starko“ und „Jimmy“, einem Deutschen Reitpony, Momo Ott mit dem Trakehner-Haflinger-Mix „Cooky“.",
        audio: "/audio/15_20190822_Reitverein_Uffenheim.m4a"
    },
    16: {
        text: "Tag 19 – 23.08.2019 – 18:31 Uhr – km 417 – Bayern, Volkach",
        text2: "Otto Eckert ist pensionierter Eisenschmelzer und am Main aufgewachsen, wo er sich als kleiner Junge im Wasser von den Lastschiffen hat mitziehen lassen.",
        audio: "/audio/16_20190823_Franke_Otto_Eckert_Eisenschmelzer.m4a"
    },
    17: {
        text: "Tag 21 – 25.08.2019 – 13:12 Uhr – km 446 – Bayern, Bergrheinfeld",
        text2: "Kirchfest 2019. Im Festzelt spielt das Seniorenblasorchester Unterfranken mit einem Durchschnittsalter von 70 Jahren unter der Leitung von Erhard Rada. Die unter dem Zeltdach hängenden Bilder zeigen die „Blüapaare“ der letzten 30 Jahre.",
        audio: "/audio/17_20190825_Kirchweih_UnterfraenkischesSeniorenBlasorchester_02.m4a"
    },
    18: {
        text: "Tag 23 – 27.08.2019 – 14:55 Uhr – km 496 – Bayern, Hohenroth",
        text2: "Tanja Steiner-Kaminsky ist Betreiberin des Cafés „Steinchen“ (deutsch für Kaminsky). Ihre Freundin Monika Graf, links neben ihr, unterstützt sie als Bäckerin. Nach 20 Jahren Arbeit als Pflegerin hat sie vor einem Jahr einen Neuanfang mit der Gründung eines Cafés gewagt.",
        audio: "/audio/18_20190827_Cafe_Steinchen_TanjaSteiner.m4a"
    },
    19: {
        text: "Tag 24 – 28.08.2019 – 15:04Uhr – km 521 – Bayern, Ginolfs",
        text2: "Basaltsee am Steinernen Haus. Ich treffe auf einen lesenden Mann, der anonym bleiben möchte. Er kommt ein paarmal im Jahr zum See, um in Ruhe in der Natur seine Lektüre zu lesen.",
        audio: "/audio/19_20190828_Mann_liest_Zeitung_am_See.m4a"
    },
    20: {
        text: "Tag 25 – 29.08.2019 – 12:57h – km 534 – Hessen, Ehrenberg-Thaiden",
        text2: "Raphaela Schuchert hat ihren Ehemann Florian vor einer Woche geheiratet. Die Familien der beiden haben zum Polterabend die Strohpuppen aufgebaut. Eine alte Tradition auf dem Land.",
        audio: "/audio/20_20190829_Polterabendtradition_Strohpuppen_RaphaelaSchuchert.m4a"
    },
    21: {
        text: "Tag 26 – 30.08.2019 – 14:19 Uhr – km 568 – Hessen, Point Alpha",
        text2: "Blick auf die ehemalige DDR-Grenze in Geisa, Thüringen. Zur Zeit des Kalten Krieges war hier die Stelle, wo sich die West- und Ostarmee gegenüberstand. Der 35 m hohe „Kirmesbaum“ wurde vor einer Woche erstmalig an dieser Stelle aufgebaut und war Teil des Festes „Grenzenlos – 30 Jahre Grenzöffnung“.",
        audio: "/audio/21_20190830_PointAlpha_AlteZiegelei_Willi_Schuetz.m4a"
    },
    22: {
        text: "Tag 28 – 01.09.2019 – 15:17 Uhr – km 606 – Hessen, Obersuhl",
        text2: "Die Kirmes in Obersuhl findet jährlich statt. Viele Zuschauer sehen sich den traditionellen Festumzug am Sonntagnachmittag an. Am Abzweig Eisenacher Straße (Euro Döner) endet der Umzug und die Kirmes wird im Festzelt weitergefeiert. Die Teilnehmer bestehen aus einer Mischung von lokalen Vereinen, Firmen, Dorfbewohnern und benachbarten Kirmesgesellschaften.",
        audio: "/audio/22_20190901_Kirmesgesellschaft_Obersuhl.m4a"
    },
    23: {
        text: "Tag 29 – 02.09.2019 – 19:27 Uhr – km 646 – Hessen, Eschwege-Niederhone",
        text2: "Die 90-jährige Gerda Schäfer und ihre fünf Jahre jüngere Schwester Theresia Klapp pflegen gemeinsam die Rosen im Vorgarten. Sie sind die letzten Lebenden von\n" +
          "elf Geschwistern, die 1945 aus Schlesien nach Hannover geflüchtet sind. Frau Schäfer hat Krankenpflegerin gelernt und bis zur Rente gearbeitet. Ihre Schwester Theresia besucht sie jährlich für drei Wochen.",
        audio: "/audio/23_20190902_Geschwister_schneiden_Rosen.m4a"
    },
    24: {
        text: "Tag 31 – 04.09.2019 – 10:42 Uhr – km 672 – Thüringen, Uder",
        text2: "Die Stadtmitte von Uder. Das ist mein Strecken Schnittpunkt. Hier habe ich auch 2017 auf meiner West-Ost-Durchquerung übernachtet.",
        audio: "/audio/24_20190903_Uder_Pizzeria_Paradies.m4a"
    },
    25: {
        text: "Tag 32 – 05.09.2019 – 12:59 Uhr – km 703 – Niedersachsen, Göttingen",
        text2: "V. l. n. r. Bettina Bode mit ihren Kindern Edith und Valentin ist in Elternzeit. Ihre Freundin Stephanie Häusser mit ihrer Tochter Matilda arbeitet momentan an ihrer Doktorarbeit und teilt sich die Woche auf in je 50 % Arbeit und Kinderbetreuung. Beide empfinden Göttingen als einen guten Ort für junge Familien.",
        audio: "/audio/25_20190905_Goettingen_Elternzeit_Muetter.m4a"
    },
    26: {
        text: "Tag 33 – 06.09.2019 – 12:21 Uhr – km 728 – Niedersachsen, Northeim/ Langenholtensen",
        text2: "Farin Bierwirth hat sich das Trialfahren durch YouTube-Tutorials beigebracht. Nach der Förderschule möchte er gern eine Ausbildung zum Zweiradmechaniker machen.",
        audio: "/audio/26_20190906_Farin_Trialfahrer.m4a"
    },
    27: {
        text: "Tag 33 – 06.09.2019 – 13:38 Uhr – km 730 – Niedersachsen, Wiebrechtshausen",
        text2: "Antje Altenweger, Kartoffelbäuerin und Lehrerin, steht im Sortierraum der historischen Kartoffelscheune des Klosterguts Wiebrechtshausen.",
        audio: "/audio/27_20190906_Kartoffelscheune_AntjeAltenweger.m4a"
    },
    28: {
        text: "Tag 33 – 06.09.2019 – 18:10 Uhr – km 740 – Niedersachsen, Sebexen",
        text2: "Die Deutsche Glasfaser verlegt Glasfaserkabel im Dorf. Am Haus „Alte Siedlung 1“ hatte der Bautrupp keine Bohrmaschine, um ein Loch durch die Mauer zu bohren. Also wurden die Leerrohre zusammengebunden. Die Bewohnerin des Hauses wartet und sehnt den Tag herbei, endlich Internet zu haben.",
        audio: "/audio/28_20190906_Glasfaserkabel_Verlegung.m4a"
    },
    29: {
        text: "Tag 34 – 07.09.2019 – 17:57 Uhr – km 768 – Niedersachsen, Bodenburg",
        text2: "Das Orgateam und der Vorstand des Männerturnvereins (MTV) Bodenburg feiern heute das 100-jährige Bestehen des Vereins in der Turnhalle. Dieser repräsentiert mit über 500 MitgliederInnen fast ein Viertel der Bewohner von Bodenburg.",
        audio: "/audio/29_20190907_100Jahrfeier_Bodenburg.m4a"
    },
    30: {
        text: "Tag 39 – 12.09.2019 – 15:15 Uhr – km 876 – Niedersachsen, Bergen-Belsen",
        text2: "Im Dokumentationszentrum der Gedenkstätte Bergen-Belsen begegne ich Diego Bernardini. Der Schweizer besucht seit Jahren Gedenkstätten von KZs und Vernichtungslagern. „Vergessen heißt verraten“ lautet sein Credo. Heute ist er das erste Mal in Bergen-Belsen. Angefangen hat es vor 12 Jahren, da war er im KZ Buchenwald. Der Besuch hatte ihn so berührt, dass er weitere Orte aufsuchen musste. ",
        audio: "/audio/30_20190912_BergenBelsen_Diego_Bernardini_ueber_das_Vergessen.m4a"
    },
    31: {
        text: "Tag 40 – 13.09.2019 – 12:24 Uhr – km 893 – Niedersachsen, Becklingen",
        text2: "In der Gaststätte „Zum Becklinger Holz“ sitzen Holger Schroeter und der Förster Andreas Sälzer beim traditionellen „Tod-Trinken“ nach einer erfolgreichen Jagd beisammen. Der Jagdgast Schroeter hatte in den Morgenstunden einen alten Hirsch in der Brunft erlegt.",
        audio: "/audio/31_20190913_Jaeger_ueber_die_Jagd_Zum_BecklingerHolz_.m4a"
    },
    32: {
        text: "Tag 40 – 13.09.2019 – 17:24 Uhr – km 912 – Niedersachsen, Wietzendorf",
        text2: "V. l. n. r.: Diandra, Timo, Steffen und Lea sind zufällige Campingnachbarn beim 20. VW-Bustreffen am Südsee-Camp. Sie feiern gemeinsam die Leidenschaft für VW-Busse. Der grüne T3 von 1979, genannt Kermit, gehört seit sechs Monaten der Bulli-Treff-Debütantin Diandra. Der schwarze 1988er T3, genannt Black Betty, ist Steffens zweiter VW-Bus. Timo ist der Fahrer für einen befreundeten blinden Bulli-Besitzer",
        audio: "/audio/32_20190913_BulliTreffen_Wietzendorf.m4a"
    },
    33: {
        text: "Tag 41 – 14.09.2019 – 15:49 Uhr – km 932 – Niedersachsen, Bispingen",
        text2: "Kathrin Bockelmann mit ihren fünf Söhnen, v. l. n. r.: Thiark, Bent, Maart, Thees und Hannek. Die Familie schaute dem Vater beim Mais häckseln zu, später suchte sich jeder der Jungs noch einen Kürbis aus. Die Namen der Söhne sind alle ostfriesischen Ursprungs.",
        audio: "/audio/33_20190914_Bispingen_FamilieBockelmann_ueber_das_Landleben_und_ostfriesische_Namen.m4a"
    },
    34: {
        text: "Tag 43 – 16.09.2019 – 19:22 Uhr – km 997 – Hamburg, St. Pauli, Reeperbahn",
        text2: "V. l. n. r. Laura, Cristina, Sirka und Annika feiern ihren ersten Besuch in Hamburg. Die Freundinnen sind auf dem Weg zum Club „Große Freiheit 36“, wo der Rapper „Fetty Wap“ ein Konzert gibt.",
        audio: "/audio/34_20190916_Reeperbahn_Frauengruppe.m4a"
    },
    35: {
        text: "Tag 44 – 17.09.2019 – 14:37 Uhr – km 1012 – Schleswig-Holstein, Norderstedt",
        text2: "Die auffällig gestalteten Bürogebäude „Nordport Towers“ sind seit 2 Wochen die Dienstags- Location für den Foodtruck „84 Grad Pork-Adillo“. Der Betreiber Roland Schmitt und sein Freund Peter Dollinger arbeiten erst seit einem Jahr mit dem Foodtruck. Peter, geboren in Mainz, begeistert sich seit der Jugend für das Kochen in all seinen Varianten.",
        audio: "/audio/35_20190917_Norderstedt_Foodtruck.m4a"
    },
    36: {
        text: "Tag 45 – 18.09.2019 – 11:55 Uhr – km 1036 – Schleswig-Holstein, Heede",
        text2: "Der Rentner Norbert Germer trimmt seine Thujen einmal im Jahr. Er benötigt drei Tage für den kompletten Schnitt und beschreibt das Resultat als „geordnetes Chaos“.",
        audio: "/audio/36_20190918_Heede_Hecke_schneiden.m4a"
    },
    37: {
        text: "Tag 46 – 19.09.2019 – 15:43 Uhr – km 1079 – Schleswig-Holstein, Huje",
        text2: "Hans-Jürgen Böge ist Milchbauer in Rente. Sein Sohn und sein Neffe arbeiten jetzt im Betrieb und sind die fünfte Generation. Früher als es noch keine Brücke über den nahegelegenen Fluss Bekau gab, hat der Urgroßvater von Hans-Jürgen die Menschen mit einer Fähre übergesetzt. Bezahlt wurde mit dem Kauf eines Getränks bei ihm.",
        audio: "/audio/37_20190919_Huje_Hans-Juergen_Boege_Milchbauer.m4a"
    },
    38: {
        text: "Tag 49 – 22.09.2019 – 12:11 Uhr – km 1164 – Schleswig-Holstein, Husum, Schobüll",
        text2: "Bei Ebbe sehen wir von der Schobüller Seebrücke direkt auf das Wattenmeer der Nordsee. Die junge Wuppertaler Familie Rudolph mit ihrer vier Monate alten Tochter Johanna verbringt dort den ersten gemeinsamen Urlaub. Sie haben sich Schobüll ausgesucht, weil es dort keine Deiche gibt und sie hier den direkten Blick auf das Meer haben.",
        audio: "/audio/38_20190922_Husum_Schobuell_Familie_Rudolph_mit_Baby.m4a"
    },
    39: {
        text: "Tag 51 – 24.09.2019 – 14:43 Uhr – km 1225 – Schleswig-Holstein, Sylt, Kampen",
        text2: "Dagmar und Volker Brase machen Urlaub auf Sylt mit Dagmars schwer dementen Vater Horst. Er leidet an der Lungen- krankheit COPD und genießt die frische Seeluft. Sie haben sich vor Ort den Elektro-Rollstuhl „CadWeazle“ geliehen, um mit dem Vater am Strand mobil zu sein. Seit dem Tod der Mutter pflegt das Ehepaar den Vater bei sich zu Hause in Erlangen, wo noch die eigenen drei Kinder leben. Heute wäre der 59. Hochzeitstag von Dagmars Eltern gewesen.",
        audio: "/audio/39_20190924_Sylt_Kampen_Ehepaar_Brase_pflegt_Vater_im_Rollstuhl.m4a"
    },
    40: {
        text: "Tag 51 – 24.09.2019 – 20:39 Uhr – km 1241 – Schleswig-Holstein, Sylt, List",
        text2: "Der Lister Ellenbogen ist ein Vogel- und Naturschutzgebiet. Es gehört einer privaten Eigentümergemeinschaft, die mit den Mauteinnahmen das Reservat unterhält. Hier liegt der nördlichste Punkt Deutschlands. Er ist markiert durch eine einfache Holztafel. Am 51. Tag erreiche ich den Punkt nach Sonnenuntergang um 20:35 Uhr. In diesem Moment bin ich der einzige Mensch dort.",
        audio: "/audio/40_20190924_Gedanken_am_noerdlichsten_Punklt_Deutschlands.m4a"
    },
    41: {
        text: "Andreas Teichmann (*1970) lebt mit seiner Familie in Essen. Er hat an der Folkwang Universität der Künste studiert und ist seit 1994 als selbstständiger Fotograf tätig. Mit Leidenschaft und Nachhaltigkeit arbeitet er an Projekten, die den Menschen und seine Umwelt untersuchen.",
        text2: "„In 101 Tagen habe ich Deutschland zweimal durchquert: Ich habe mir für die täglich zufälligen Begegnungen Zeit genommen, zugehört und fotografiert. Um genau hinzusehen, habe ich ein langsames aber hochauflösendes Kamerasystem benutzt. Diese Bilder und Geschichten habe ich während meiner Wanderungen auf meinem Blog www.50days.de veröffentlicht.“",
        audio: "/audio/41_20211213_Begruessung_AT.m4a"
    },
}

export class AudioPlayer extends React.PureComponent {

    render () {

        const {mobile, audioId} = this.props;

        const containerStyle = mobile ? {paddingTop: 70} : {};
        const playerStyle = mobile ? {outline:"none", width:"100%"} : {outline:"none"};

        return (
            <OuterTextContainer right style={containerStyle}>
                <TextContainer white>
                    <Text tall black>{playerContent[audioId].text}</Text>
                    <Text bottompadding underlined>
                        <ReactAudioPlayer
                            src={window.location.origin + playerContent[audioId].audio}
                            style={playerStyle}
                            controls
                            controlsList="nodownload"
                        />
                    </Text>
                    <Text bottompadding black>{playerContent[audioId].text2}</Text>
                    {mobile && <Text bottompadding black style={{justifyContent: "center", display: "flex", margin: 0, padding: 0, marginTop: 50}}><a href={window.location.origin}>Alle Bilder und Stories >></a><br/><br/><br/><br/><br/><br/><br/></Text>}
                </TextContainer>
            </OuterTextContainer>
        );
    }

}