import React from "react";
import styled, {css} from "styled-components";

export const ImageContainer = styled.div`
  z-index: -1; 
  position: fixed;
  width: 100%; 
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  ${props => props.showImage ? css`opacity: 1;` : css`opacity: 0;`}
  transition: opacity 500ms ease-in-out;
  ${props => props.imagePosition === "left" && window.innerWidth > 1200 ? css`height: 100%;position:absolute;left:0px;` :
      props.imagePosition === "right" && window.innerWidth > 1200 ? css`height: 100%;position:absolute;right:0px;` :
        window.innerWidth > 1200 ? css`height: 100%;position:absolute;top:0px;` : css`width:100%;`
  };
`;

export const ImagePreloader = styled.img`
  opacity: 0;
  z-index: -999; 
  position: fixed;
  width: 100%; 
  height: 100vh;
`;

export const Section = styled.div`
  ${props => props.show ? css`opacity: 1;` : css`opacity: 0;`}
  transition: opacity 750ms ease-in-out;
  width: 100%;
`;

export const OuterTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props => props.right ? "flex-end;" : props.left ? "flex-start;" : "center;"}
`;

export const TextContainer = styled.div`
  background-color: ${props => props.white ? 'rgba(255,255,255,0.5);' : 'rgba(0,0,0,0.5);'}
  width: fit-content;
  ${props => {
  if (window.innerWidth > 1000) {
    return css`max-width:40%;`;
  } else if (window.innerWidth > 700) {
    return css`max-width:70%;`;
  } else {
    return css`max-width:100%;`;
  }
}}
`;

export const TextOnlyContainer = styled.div`
  line-height: 30px;
  width: ${props => window.innerWidth > 1000 ? "50%;" : window.innerWidth > 700 ? "80%;" : "97%;"}
`;

export const PageContainer = styled.div`
  line-height: 30px;
  width: ${props => window.innerWidth > 1000 ? "60%;" : "97%;"}
`;

export const Text = styled.div`
  color: ${props => props.black ? "#333" : "white"};
  // font-family: Roboto, Arial, sans-serif;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: ${props => props.xl ? "34px;" : props.tall ? "20px;" : "16px;"};
  line-height: ${props => props.xl ? "40px;" : "27px;"};
  padding-top: 20px;
  padding-bottom: ${props => props.bottompadding ? "20px;" : "0;"};
  margin-left: 30px;
  margin-right: 30px;
  ${props => props.underlined && !props.black && css`border-bottom: 1px white solid;padding-bottom: 10px;`}
  ${props => props.underlined && props.black && css`border-bottom: 1px black solid;padding-bottom: 10px;`}
  ${props => props.xl && css`letter-spacing: 1px;`}
`;